import * as React from "react";
import Helmet from "react-helmet";

import { Link } from "gatsby";

import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import { respondTo } from "../_respondTo";

import { StaticImage } from "gatsby-plugin-image";
import logo from "../images/twg_logo.svg";

import { PageWrapper, TextWrapper } from "../components/layout";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import NextButton from "../components/next-button";

const GlobalStyle = createGlobalStyle`
  body{
    margin:0;
    font-family: sans-serif;
  }
`;

const LogoWrapper = styled.div`
  margin: auto;
  width: 25%;
  max-width: 175px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  margin: auto;
  text-align: center;
  color: #020f73;
  font-family: sans-serif;
`;

// markup
const CareersPage = () => {
  return (
    <>
      <Helmet>
        {" "}
        <title>Careers | True Wealth Group</title>
        <meta name="description" content="Careers at True Wealth Group" />
        <script defer src="https://unpkg.com/@tinybirdco/flock.js" data-host="https://api.tinybird.co" data-token="p.eyJ1IjogImI3YzA5NWQxLThiMTctNGQ5Ni04ODUwLWUxNjM0YmU5NzgyOCIsICJpZCI6ICI4Njg5NGY0MC00ZjBlLTRiMGMtYTI2YS0xMmNkZTBlYjEyOWMifQ.cXvEL-7r0R4anvPPduWPoDsUKPPZeyI54Z2BuA_L5E8"></script>
      </Helmet>
      <PageWrapper>
        <Navigation></Navigation>
        <GlobalStyle />
        <StaticImage
          alt="Background image of person walking up path on a mountain"
          src={"../images/bg-img.jpg"}
          formats={["auto", "webp", "avif"]}
          style={{
            position: "fixed",
            top: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "-1",
          }}
          loading="eager"
        />
        <LogoWrapper>
          <img
            src={logo}
            style={{ width: "100%", paddingTop: "25%", aspectRatio: "1/1" }}
            alt="True Wealth Group logo"
          />
        </LogoWrapper>{" "}
        <ContentWrapper>
          <h1>Careers</h1>
        </ContentWrapper>
        <TextWrapper>
          <h2>We're hiring!</h2>
          <p>
            We are building an exceptional business and that requires
            exceptional people.
          </p>{" "}
          <p>
            One of our 2030 goals is to be the employer of choice in our sector
            and we anticipate that many planners and technical staff will be
            internally ‘home grown’ via the True Wealth Academy. The firms we
            acquire will also bring with them great staff whose careers we are
            keen to develop and align with our culture and values. Last, but by
            no means least, is our desire to attract the profession's top talent
            as our reputation and awareness of our project grows.
          </p>
          <p>
            We hire, develop and promote using the guiding principles set out in
            our <Link to="/our-purpose#corevalues">core values</Link>.
          </p>{" "}
          <p>
            Our business is growing through our acquisition strategy and our JV
            growth strategy. Keeping pace with the potential of the opportunity
            afforded to us by our proposition, and the amount of wealthy
            individuals and business owners who would like to avail themselves
            of it, is an ongoing challenge!
          </p>{" "}
          <p>
            So, if you want to be part of an organisation that strives to
            deliver a truly world class proposition and genuinely has clients’
            best interests at the heart of its business, please{" "}
            <Link to="/contact">get in touch</Link>. If you are the right
            calibre of individual we think you’ll find we’ll both be glad you
            did!!
          </p>
        </TextWrapper>
      </PageWrapper>
      <Footer></Footer>
    </>
  );
};

export default CareersPage;

export const Head = () => (
  <>
    <title>Careers | True Wealth Group</title>
    <meta name="description" content="Careers at True Wealth Group" />
    <meta name="keywords" content="True Wealth Group" />
  </>
);
